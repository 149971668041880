import { createContext } from "react";

interface IUser {
  userRoleId: string;
  image?: string | { display_image: string };
  // Add other properties of the user here
}

interface IAppContextType {
  token: string;
  setToken: (token: string) => void;
  refreshToken: string;
  setRefreshToken: (refToken: string) => void;
  user: IUser;
  setUser: (user: string) => void;
  theme: string;
  setTheme: (theme: string) => void;
}

const AppContext = createContext<IAppContextType>({
  token: "",
  setToken: () => {},
  refreshToken: "",
  setRefreshToken: () => {},
  user: { userRoleId: "", image: "" },
  setUser: () => {},
  theme: "",
  setTheme: () => {},
});

// const AppContext = createContext({});

export default AppContext;
