import React, { ReactNode } from "react";
import {
  FormControl,
  Select,
  SelectChangeEvent,
  FormHelperText,
} from "@mui/material";
import DoubleArrowIcon from "./customIcons/DoubleArrowIcon";

interface SingleFormControlProps {
  disabled?: boolean;
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  error?: boolean;
  helperText?: string;
  marginTop?: string;
  selectedItemStyle?: React.CSSProperties | undefined;
  children: React.ReactNode;
}

const SingleFormControl: React.FC<SingleFormControlProps> = ({
  disabled,
  value,
  onChange,
  error = false,
  helperText = "",
  children,
  marginTop = "-3px",
  selectedItemStyle,
}) => {
  return (
    <FormControl
      disabled={disabled}
      variant="outlined"
      fullWidth
      sx={{
        borderRadius: "10px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderRadius: "10px",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "grey",
          },
          "&:hover fieldset": {
            borderColor: "grey",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#D5D5D5",
          },
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "#D5D5D5",
        },
        "& .MuiFormHelperText-root": {
          color: "#ff0000",
          marginTop: "10px",
          marginLeft: 0
        }
      }}
    >
      <Select
        IconComponent={DoubleArrowIcon}
        value={value}
        onChange={onChange}
        displayEmpty
        sx={{
          borderRadius: "10px",
          height: "45px",
          backgroundColor: disabled ? "#F3F3F3" : "transparent", // Background color for the Select
          fontFamily: "Inter", // Font family
          fontSize: "14px", // Font size
          fontWeight: 400, // Font weight
          lineHeight: "24px", // Line height
          textAlign: "left", // Text alignment
          color: disabled ? "#B0B0B0" : "#858585", // Text color changes when disabled
          "& .MuiSelect-select": {
            padding: "20px 14px",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16.94px",
            textAlign: "left",
            minHeight: 0,
            ...selectedItemStyle,
          },
          "& .MuiOutlinedInput-input.MuiSelect-select": {
            minHeight: 0,
          },

        }}
      >
        {children}
      </Select>
      <FormHelperText
        sx={{
          lineHeight: "0.66",
          visibility: error ? "visible" : "hidden",
          color: error ? "#CE423A" : "transparent",
        }}
      >
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default SingleFormControl;
