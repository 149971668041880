import { useState } from "react";
import "./text-input.scss";
import {
  Container,
  CssBaseline,
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  TextFieldProps,
} from "@mui/material";
// import "./../../pages/login/login.scss";
// import "./../../pages/signup/signup.scss";

interface CustomTextFieldProps {
  id: string;
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: any;
  type?: string;
  autoFocus?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  className?: string;
  error?: boolean;
  helperText?: string;
  FormHelperTextProps?: React.ComponentProps<
    typeof TextField
  >["FormHelperTextProps"];
}
const CustomTextField: React.FC<CustomTextFieldProps> = ({
  id,
  label,
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  type = "text",
  autoFocus = false,
  required = false,
  fullWidth = true,
  className = "text-field2",
  helperText,
  FormHelperTextProps = {
    sx: { pt: 0, m: 0, px: 1, lineHeight: "normal" },
  },
  ...rest
}) => {
  const [error, setError] = useState<string>("");

  return (
    <TextField
      margin="normal"
      required={required}
      fullWidth={fullWidth}
      id={id}
      label={label}
      name={name}
      type={type}
      autoFocus={autoFocus}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      error={!!error}
      className={`${className} text-input-field`}
      helperText={helperText}
      InputLabelProps={{
        className: "text-field-label2",
        sx: {
          // alignItems: "center",
          lineHeight: "normal",

          // fontSize: '0.5rem',
        },
      }}
      InputProps={{
        sx: {
          lineHeight: "normal", // Use normal or a specific line-height value
        },
      }}
      FormHelperTextProps={FormHelperTextProps}
      {...rest}
    />
  );
};

export default CustomTextField;
