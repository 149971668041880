import { useState, createContext, useContext, useEffect } from "react";
import AppContext from "./app-context";

const AppProvidor = ({ children }: any) => {
  const [token, setToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [user, setUser] = useState({});
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user") as string);
    const token = sessionStorage.getItem("token") as string;
    const refToken = sessionStorage.getItem("refreshToken") as string;

    setUser(user);
    setToken(token);
    setRefreshToken(refToken);
  }, []);

  return (
    <AppContext.Provider
      value={{
        token,
        setToken,
        refreshToken,
        setRefreshToken,
        user: { userRoleId: "", image: "", ...user },
        setUser,
        theme,
        setTheme,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvidor;
