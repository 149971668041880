import { useEffect, useState } from "react";
import Pagination from "../pagination/pagination";
import useFetch from "../../hooks/useFetch";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  SellOutlined as SellOutlinedIcon,
  EmailOutlined as EmailOutlinedIcon,
  PhoneOutlined as PhoneOutlinedIcon,
  PlaceOutlined as PlaceOutlinedIcon,
  MoreVertOutlined as MoreVertOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
} from "@mui/icons-material";

import addIcon from "./../../assets/img/add-icon.svg";
import { useNavigate } from "react-router-dom";
import "./data-table.scss";
import instance from "../../utils/axios";
import DeleteModel from "../../pages/admin/deleteModel";
import CustomMenu from "../parent-details/custom-menu-control";
import {
  deleteProperty,
  deleteCompanyAndServices,
} from "../../../src/services/adminServices";

interface IDataTableProps {
  headList: [];
  bodyList: any;
  dataFetch: string;
  handleNavigate: () => void;
  handleEditSubRowRecord: (companyCode: string, id?: string) => void;
  title: string;
  buttonTitle: string;
  parentCode: string;
  type: string;
  disabledPagination?: boolean;
  handleDeleteConfirmed: (x: string, y: any, z: any) => void;
  deleteType: string; //'main' | 'regional' | 'service' | 'company' | 'branch';;
}

const CustomDataTable: any = ({
  headList,
  bodyList,
  dataFetch,
  handleNavigate,
  handleEditSubRowRecord,
  title,
  buttonTitle,
  parentCode,
  type,
  disabledPagination = false,
  handleDeleteConfirmed,
  // deleteType
}: IDataTableProps) => {
  const navigate = useNavigate();
  const [isDeleteData, setIsDeleteData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [totalEntries, setTotalEntries] = useState(5);
  const [dataURL, setDataURL] = useState<string>(dataFetch);
  const [isPagination, setIsPagination] = useState(false);
  // const { data, loading, error } : any= useFetch(dataURL);
  const [tableDataList, setTableDataList] = useState<any>(null);
  const [selectedParentProperty, setSelectedParentProperty] =
    useState<any>(null);
  const [deleteType, setDeleteType] = useState<
    "regional" | "branch" | "main" | null
  >(null);

  const [parentAnchorEl, setParentAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [regionalAnchorEls, setRegionalAnchorEls] = useState<
    Map<number, HTMLElement>
  >(new Map());
  const [selectedRegionalProperty, setSelectedRegionalProperty] =
    useState<any>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedBranchCompany, setSelectedBranchCompany] = useState<any>(null);

  useEffect(() => {
    parentCode && fetchParentDetails();
  }, [parentCode, currentPage, entriesPerPage, isDeleteData]);

  const fetchParentDetails = async () => {
    try {
      const response = await instance.get(
        `${dataFetch}${parentCode}?index=${currentPage}&limit=${entriesPerPage}`,
      );
      const { status, data } = response;

      if (status === 200) {
        const responseData = data?.data.data || data?.data;

        setTableDataList(responseData?.management_units || responseData);
        setCurrentPage(data.data.currentPage ?? 1);
        setEntriesPerPage(data.data.limit ?? 5);
        setTotalEntries(data.data.totalCount);
        setIsPagination(true);
      } else if (status === 400) {
        setTableDataList([]);
        setTotalEntries(0);
        setIsPagination(false);
        console.warn("Received status 400: clearing table data.");
      }
    } catch (error: any) {
      console.error("Error fetching parent details:", error);

      setTableDataList([]);
      setTotalEntries(0);
      setIsPagination(false);
    }
  };

  const deleteHandler = (x: string, y: any, z: any) => {
    handleDeleteConfirmed(x, y, z);
    fetchParentDetails();
  };

  const bodyComponent = (item: any, index: number) => {
    return (
      <tr key={index}>
        {bodyList(item)}
        <td>
          <IconButton
            onClick={(e) => handleRegionalMenuClick(e, item, index)}
            style={{ cursor: "pointer" }}
          >
            <MoreVertOutlinedIcon />
          </IconButton>
          <CustomMenu
            parentAnchorEl={regionalAnchorEls.get(index) ?? null}
            handleCloseMenu={handleCloseMenu}
            handleEdit={() =>
              handleEditSubRowRecord(item.company_code, item.id)
            }
            handleDelete={handleDeleteRegionalProperty}
          />
        </td>
      </tr>
    );
  };

  const handleCloseMenu = () => {
    setRegionalAnchorEls(new Map());
  };

  const handleRegionalMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    property: any,
    index: number,
  ) => {
    setRegionalAnchorEls(
      (prev) => new Map(prev.set(index, event.currentTarget)),
    );
    setSelectedRegionalProperty(property);
  };
  const handleBranchMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    property: any,
    index: number,
  ) => {
    setRegionalAnchorEls(
      (prev) => new Map(prev.set(index, event.currentTarget)),
    );
    setSelectedBranchCompany(property); // Assign the correct property here
  };

  const handleDeleteRegionalProperty = () => {
    setDeleteType(type === "company" ? "branch" : "regional");
    setDeleteDialogOpen(true);
    handleCloseMenu();
  };

  const handleEntriesPerPageChange = (entries: number) => {
    setEntriesPerPage(entries);
    setCurrentPage(1);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    handleCloseMenu();
    setDeleteType(null);
  };

  let rowHeight =
    tableDataList && tableDataList.length > 0
      ? 80 * (entriesPerPage == 5 ? 5 : entriesPerPage)
      : 100;

  return (
    <div className="data-table">
      {disabledPagination}
      <Box className="table-container" style={{ height: rowHeight + 140 }}>
        <Box className="header-container">
          <h2 className="table-heading">{title}</h2>
          <Button
            variant="contained"
            className="add-regional-property-button"
            startIcon={<img src={addIcon} alt="Add" />}
            onClick={handleNavigate}
            disabled={parentCode === null}
          >
            {buttonTitle}
          </Button>
        </Box>
        <table className="property-table">
          <thead>
            <tr>
              {headList.map((item: any, index: number) => (
                <th key={index}>{item}</th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(tableDataList) && tableDataList.length > 0 ? (
              tableDataList.map((item: any, index: number) =>
                bodyComponent(item, index),
              )
            ) : (
              <tr>
                <td colSpan={headList.length + 1}>No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>

      {!disabledPagination && isPagination && (
        <Pagination
          currentPage={currentPage}
          totalEntries={totalEntries}
          entriesPerPage={entriesPerPage}
          onPageChange={setCurrentPage}
          onEntriesPerPageChange={handleEntriesPerPageChange}
          isDisabled={!(tableDataList && tableDataList.length > 0)}
        />
      )}

      <DeleteModel
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onDelete={() => {
          setDeleteDialogOpen(false);
          setIsDeleteData((prev) => !prev);
          deleteHandler(
            deleteType ?? "",
            selectedRegionalProperty,
            selectedBranchCompany,
          );
        }}
        deleteType={deleteType!}
      />
    </div>
  );
};

export default CustomDataTable;
