import { Box, Card, CardContent, Typography } from "@mui/material";

interface CardMainProps {
  header: string;
  subHeader: string;
  cardContent: React.ReactNode;
  width?: string | number; // Custom width (optional)
  height?: string | number; // Custom height (optional)
  gap?: string | number; // Gap between elements (optional)
  borderRadius?: string; // Custom border radius (optional)
  opacity?: number; // Opacity (optional)
  backgroundColor?: string; // Background color for the header (optional)
  headerStyles?: React.CSSProperties | undefined;
}
const CardMain: React.FC<CardMainProps> = ({
  header,
  headerStyles,
  subHeader,
  cardContent,
  width, // Default width
  height, // Default height
  // width = "311px", // Default width
  // height = "146px", // Default height
  gap = "10px", // Default gap
  borderRadius = "20px 0px 0px 0px", // Default border radius
  opacity = 1, // Default opacity
  backgroundColor = "#f0f0f0", // Default background color for the header
}) => {
  const px = 8;
  return (
    <>
      <Card
        variant="outlined"
        sx={{
          mb: 2,
          borderRadius: "15px",
          boxShadow: 2,
          width: width,
          height: height,
        }}
      >
        <Box
          sx={{
            backgroundColor: backgroundColor || "#f0f0f0",
            py: 1.5,
            px: px,
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
          }}
        >
          <Typography
            variant="h6"
            sx={{ mb: 0, fontWeight: 600, ...headerStyles }}
          >
            {header}
          </Typography>
        </Box>
        <Typography
          variant="h6"
          sx={{
            mt: 2,
            mb: 0,
            px: px,
            fontWeight: 400,
            fontSize: "16px",
            font: "inter",
          }}
        >
          {subHeader}
        </Typography>
        <CardContent sx={{ pt: 0, px: px }}>{cardContent}</CardContent>
      </Card>
    </>
  );
};
export default CardMain;
