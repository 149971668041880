import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { DeleteOutlined as DeleteOutlinedIcon } from "@mui/icons-material";
import "./deleteModel.scss";
import del from "../../assets/img/del-icon.png";

interface DeleteModelProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  deleteType: string; // 'main' | 'regional' | 'service' | 'company' | 'branch';
}

const DeleteModel: React.FC<DeleteModelProps> = ({
  open,
  onClose,
  onDelete,
  deleteType,
}) => {
  const getDialogText = () => {
    if (deleteType === "main") {
      return (
        <Typography className="delete-message">
          Are you sure you want to delete this property? Deleting will remove
          all associated location based properties, sub-properties, amenities,
          and related data. This action cannot be undone. Please confirm to
          proceed.
        </Typography>
      );
    } else if (deleteType === "regional") {
      return (
        <Typography className="delete-message">
          Are you sure you want to delete this location-based property? This
          will permanently remove all associated sub-properties, amenities, and
          data linked to this location-based property. This action cannot be
          undone. Please confirm to proceed.
        </Typography>
      );
    } else if (deleteType === "company") {
      return (
        <Typography className="delete-message">
          Are you sure you want to delete this Company? Deleting will remove all
          associated branches, services and related data. This action cannot be
          undone. Please confirm to proceed.
        </Typography>
      );
    } else if (deleteType === "branch") {
      return (
        <Typography className="delete-message">
          Are you sure you want to delete this branch? Deleting will remove all
          associated services and related data. This action cannot be undone.
          Please confirm to proceed.
        </Typography>
      );
    } else if (deleteType === "service") {
      return (
        <Typography className="delete-message">
          Are you sure you want to delete this service? Deleting will remove all
          related data. This action cannot be undone. Please confirm to proceed.
        </Typography>
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-confirmation-dialog"
      maxWidth="xs"
      fullWidth
      className="model-container delete-modal"
    >
      <DialogTitle
        className="header"
        //  sx={{ display: "flex", justifyContent: "center" }}
      >
        <img
          src={del}
          // style={{ width: "56px", height: "55px", alignItems: "center" }}
        />
      </DialogTitle>

      <DialogContent>
        <Typography
          variant="h6"
          className="h6"
          // align="center"
          // fontSize="22px"
          // fontFamily={'"Inter", sans-serif'}
          // sx={{ marginBottom: "5px", fontWeight: 600 }}
        >
          Delete Confirmation
        </Typography>

        {getDialogText()}
      </DialogContent>

      <DialogActions
        // sx={{
        //   justifyContent: "space-between",
        //   padding: "16px",
        //   fontSize: "16px",
        // }}
        className="dialog-action-wrap"
      >
        <Button className="cancel-button" variant="outlined" onClick={onClose}>
          Cancel
        </Button>

        <Button
          className="delete-button"
          variant="contained"
          onClick={onDelete}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModel;
