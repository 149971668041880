import React from "react";
import { Snackbar, Alert } from "@mui/material";
import "./AlertMessage.scss";

interface AlertMessageProps {
  open: boolean;
  onClose: () => void;
  severity: "success" | "error" | "warning" | "info";
  message: string;
}

const severityTo = {
  success: "Success!",
  error: "Error!",
  warning: "Warning",
  info: "Info",
};

const AlertMessage: React.FC<AlertMessageProps> = ({
  open,
  onClose,
  severity,
  message,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      className="snackbar"
      sx={{
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3)",
        borderRadius: "6px",
      }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{ width: "100%" }}
        className="custom-alert"
        icon={false}
      >
        <strong style={{ fontWeight: "700" }}>{severityTo[severity]}</strong>
        <br />
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
