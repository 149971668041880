import React from "react";
import { LockOutlined } from "@mui/icons-material";
import logoImage from "./../../assets/img/logo.png";
import googleLogo from "./../../assets/img/gogal.png";
import appleLogo from "./../../assets/img/apple.png";
import cam from "./../../assets/img/cam.png";
import tool from "./../../assets/img/tool.png";

import {
  Container,
  CssBaseline,
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import "./animation.scss";

const Animation = () => {
  return (
    <Grid item xs={false} className="left-side">
      <div className="animation">
        <div className="animated-rectangle1">
          Capture the <br />
          problem, and
          <br /> we’ll send a pro in <br />a flash.
        </div>
        <div
          className="animated-rectangle2"
          style={{ backgroundImage: `url(${cam})` }}
        ></div>
        <div
          className="animated-rectangle2"
          style={{ backgroundImage: `url(${tool})` }}
        ></div>
        <div className="animated-rectangle1">
          Fix it up and <br />
          make it look like <br />
          new again.
        </div>
      </div>
      <Typography className="bottom">
        From quick fixes to dream makeovers, our pros are just a tap away. Your
        perfect home is within reach—sign in and let’s make it happen!
      </Typography>
    </Grid>
  );
};

export default Animation;
