import React from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Chip,
  OutlinedInput,
  Typography,
  FormHelperText,
  TextField,
  SelectChangeEvent,
} from "@mui/material";
import DoubleArrowIcon from "./customIcons/DoubleArrowIcon";
import QuantityAdjuster from "./QuantityAdjuster";
import DeleteIcon from "./customIcons/DeleteIcon";

interface AmenitySelectionProps {
  amenities: { prettyName: string }[];
  amenityDetails: { [key: string]: number };
  error: boolean;
  onAmenityChange: (event: SelectChangeEvent<string[]>) => void;
  onDeleteAmenity: (key: string, event: React.MouseEvent) => void;
  onQuantityChange: (amenity: string, increment: boolean) => void;
}

const SelectAmenities: React.FC<AmenitySelectionProps> = ({
  amenities,
  amenityDetails,
  error,
  onAmenityChange,
  onDeleteAmenity,
  onQuantityChange,
}) => {
  const selectedAmenities = Object.keys(amenityDetails) as string[];
  return (
    <Box>
      <FormControl
        fullWidth
        variant="outlined"
        sx={{
          borderRadius: "10px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "10px",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "grey",
            },
            "&:hover fieldset": {
              borderColor: "grey",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#D5D5D5",
            },
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#D5D5D5",
          },
        }}
      >
        <Select
          MenuProps={{
            PaperProps: {
              sx: {
                marginTop: "20px",
              },
            },
          }}
          IconComponent={DoubleArrowIcon}
          displayEmpty
          multiple
          value={selectedAmenities}
          onChange={onAmenityChange}
          input={<OutlinedInput />}
          sx={{
            "& .MuiSelect-select": {
              padding: "10px 10px",
            },
            fontSize: "14px",
          }}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <span style={{ color: "#6b6b6b", fontSize: "14px" }}>
                  Select Amenity(s)
                </span>
              );
            }
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {(selected as string[]).map((value) => (
                  <Chip
                    deleteIcon={
                      <DeleteIcon
                        height={8}
                        width={8}
                        onClick={() => {
                          console.log("hii");
                        }}
                      />
                    }
                    onDelete={(event) => onDeleteAmenity(value, event)}
                    onMouseDown={(event) => event.stopPropagation()}
                    key={value}
                    label={
                      amenities.find((opt) => opt.prettyName === value)
                        ?.prettyName
                    }
                    sx={{
                      mx: 0.5,
                      backgroundColor: "#E8F1EC",
                      color: "#53BA83",
                      border: "0.5px solid #7ACDA1",
                      fontSize: "14px",
                      fontWeight: "400",
                      padding: "0 10px",
                      borderRadius: "8px",
                    }}
                  />
                ))}
              </Box>
            );
          }}
        >
          <MenuItem disabled value="" sx={{ fontSize: "14px" }}>
            <span style={{ color: "#6b6b6b" }}>Select Amenity(s)</span>
          </MenuItem>
          {amenities.map((option) => (
            <MenuItem
              key={option.prettyName}
              value={option.prettyName}
              sx={{ fontSize: "14px" }}
            >
              {option.prettyName}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText
          sx={{
            lineHeight: "0.66",
            visibility: error ? "visible" : "hidden",
            color: error ? "red" : "transparent",
          }}
        >
          Please select an Option
        </FormHelperText>
      </FormControl>

      {selectedAmenities.length > 0 && (
        <Typography
          variant="h6"
          sx={{
            mt: 3,
            mb: 0,
            fontWeight: 400,
            fontSize: "16px",
            font: "inter",
          }}
        >
          Amenity Detail(s)
        </Typography>
      )}
      <Box sx={{ mt: 0 }}>
        {Object.entries(amenityDetails).map(([amenity, quantity]) => (
          <Box
            key={amenity}
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <TextField
              disabled
              size="small"
              sx={{
                "& .MuiInputBase-input": {
                  padding: "10px 10px",
                  fontSize: "14px",
                  color: "#333333",
                },
                borderRadius: "10px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#D5D5D5",
                  },
                  "&:hover fieldset": {
                    borderColor: "#D5D5D5",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#D5D5D5",
                  },
                },
              }}
              InputProps={{
                style: { fontSize: "14px", color: "#888888" },
              }}
              label=""
              value={
                amenities.find((opt) => opt.prettyName === amenity)
                  ?.prettyName || amenity
              }
              variant="outlined"
              fullWidth
            />
            <QuantityAdjuster
              quantity={quantity}
              onIncrement={() => onQuantityChange(amenity, true)}
              onDecrement={() => onQuantityChange(amenity, false)}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SelectAmenities;
